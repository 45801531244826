import type { Ref } from 'vue';
import { defineStore } from 'pinia';
import type { CartItemType } from '~/composables/cart/types/CartType';

type CartStoreType = {
  cartList: Ref<CartItemType[]>;
	setCartStore:(cart: CartItemType[] | null)=> void;
	changeCartItem:(cartItem: CartItemType)=>void;
	cartTotalCount:Ref<number>;
	cartTotalPrice:Ref<number>;
}

export const cartStore = defineStore('cart-store', (): CartStoreType => {
  const cartList = ref<CartItemType[]>([]);

  const setCartStore = (cart: CartItemType[] | null) => {
    if (cart) {
      cartList.value = [...cart];
    } else {
      cartList.value = [];
    }
  };

  const cartTotalPrice = computed(() => {
    return cartList.value.reduce((totalPrice, cart) => totalPrice + cart.sum, 0);
  });

  const cartTotalCount = computed(() => {
    return cartList.value.reduce((totalCount, cart) =>
      totalCount + cart.cart_products.reduce((count, product) => count + product.count, 0), 0);
  });

  const changeCartItem = (cartItem: CartItemType) => {
    if (!cartList.value.length) {
      cartList.value.push(cartItem);
    }
    let isItemMissing = true;

    cartList.value.forEach((t, idx) => {
		 if (t.id === cartItem.id) {
			 isItemMissing = false;
			 cartList.value[idx] = cartItem;
			 return;
		 }
    });

    if (isItemMissing) {
      cartList.value.push(cartItem);
    }
  };

  return {
	  cartTotalCount,
	  cartTotalPrice,
	  cartList,

	  changeCartItem,
	  setCartStore
  };
});
