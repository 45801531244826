import type { RestaurantListType, RestaurantType } from '~/composables/restaurant/types/RestaurantType';
import type { ProductListResponceType, ProductType } from '~/composables/product/types/UseProductType';
import type {
  WishAddProductReqType,
  WishAddRestaurantReqType, WishRemoveProductReqType,
  WishRemoveRestaurantReqType
} from '~/composables/wish/types/WishTypes';
import type { ServerResponseCommonType } from '~/types/commons';
import { type WishListType, wishStore as store } from '~/stores/wish-store';
import type { Ref } from 'vue';
type UseWishType = {
	wishGetRestaurantList:()=> Promise<RestaurantType[]>;
	wishGetProductList:()=>Promise<ProductType[]>;
	wishAddProduct:(options:WishAddProductReqType)=>Promise<boolean>;
	wishAddRestaurant:(options:WishAddRestaurantReqType)=>Promise<boolean>;
	wishRemoveRestaurant:(options:WishRemoveRestaurantReqType)=>Promise<boolean>;
	wishRemoveProduct:(options:WishRemoveProductReqType)=>Promise<boolean>;
	wishStoreList: Ref<WishListType>;
	isLoading:Ref<boolean>;
	wishTotalCount:Ref<number>;
}

export function useWish ():UseWishType {
  const { $api } = useNuxtApp();
  const wishStore = store();
  const { wishStoreList, wishTotalCount } = storeToRefs(wishStore);
  const isLoading = useState<boolean>('is-wish-loading', () => true);

  async function wishGetRestaurantList ():Promise<RestaurantType[]> {
    const res = await $api<ServerResponseCommonType<RestaurantListType>>('user/favourite/getRestaurantsList')
	    .catch((err) => {
		    isLoading.value = false;
        throw err;
      });

    if (res && res.data) {
	    wishStore.changeWishList({
		    type: 'restaurant',
		    items: res.data.list
	    });
      isLoading.value = false;
      return res.data.list;
    }
	  isLoading.value = false;
    return [];
  }

  async function wishGetProductList ():Promise<ProductType[]> {
    const res = await $api<ServerResponseCommonType<ProductListResponceType>>('user/favourite/getProductsList')
	    .catch((err) => {
		    isLoading.value = false;
        throw err;
      });

	  if (res && res.data) {
		  wishStore.changeWishList({
			  type: 'product',
			  items: res.data.list
		  });
		  isLoading.value = false;
		  return res.data.list;
	  }
	  isLoading.value = false;
	  return [];
  }

  async function wishAddProduct (option:WishAddProductReqType):Promise<boolean> {
    const res = await $api<ServerResponseCommonType>('user/favourite/addProduct', {
      method: 'POST',
      body: { product_id: option.id }
    }).catch((err) => { throw err; });

    return !!(res && res.message === 'Success');
  }

  async function wishAddRestaurant (option:WishAddRestaurantReqType):Promise<boolean> {
    const res = await $api<ServerResponseCommonType>('user/favourite/addRestaurant', {
      method: 'POST',
	    body: { restaurant_id: option.id }
    }).catch((err) => { throw err; });

    return !!(res && res.message === 'Success');
  }

  async function wishRemoveProduct (option:WishRemoveProductReqType):Promise<boolean> {
    const res = await $api<ServerResponseCommonType>('user/favourite/deleteProduct', {
      method: 'POST',
	    body: { product_id: option.id }
    }).catch((err) => { throw err; });

    return !!(res && res.message === 'Success');
  }

  async function wishRemoveRestaurant (option:WishRemoveRestaurantReqType):Promise<boolean> {
    const res = await $api<ServerResponseCommonType>('user/favourite/deleteRestaurant', {
      method: 'POST',
	    body: { restaurant_id: option.id }
    }).catch((err) => { throw err; });

    return !!(res && res.message === 'Success');
  }

  return {
	  wishTotalCount,
	  isLoading,
	  wishStoreList,
	  wishAddRestaurant,
    wishRemoveRestaurant,
	  wishRemoveProduct,
	  wishAddProduct,
	  wishGetProductList,
    wishGetRestaurantList
  };
}
