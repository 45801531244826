import { defineStore } from 'pinia';

import type { Ref } from 'vue';
import type { CategoryType } from '~/composables/category/types/CategoryType';

type CategoryStoreType = {
	categoryList: Ref<CategoryType[]>;
	setCategoryList:(categorys: CategoryType[]) => void;
}
export const categoryStore = defineStore('category-store', ():CategoryStoreType => {
  const categoryList = ref<CategoryType[]>([]);

  function setCategoryList (categories: CategoryType[]) {
    categoryList.value = categories;
  }

  return {
    categoryList,
	  setCategoryList
  };
});
