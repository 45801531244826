import { default as indexE2n2CxFxiBMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/about-company/index.vue?macro=true";
import { default as _91slug_93Pmv45xAs8dMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/actions/[slug].vue?macro=true";
import { default as indexUI0Ig22GmWMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/actions/index.vue?macro=true";
import { default as _91slug_93ky1d6LrFkLMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/blog/[slug].vue?macro=true";
import { default as indexT9nZsWVC9GMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/blog/index.vue?macro=true";
import { default as _91slug_93qeSohuARJ2Meta } from "/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/bluda/[slug].vue?macro=true";
import { default as index4XRIHNslmaMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/bluda/index.vue?macro=true";
import { default as statusWqwOloi09TMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/cabinet/payment/status.vue?macro=true";
import { default as index7aDTlmHafdMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/cart/index.vue?macro=true";
import { default as successYiaIobbrnVMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/cart/success.vue?macro=true";
import { default as _91slug_93UTOiCgD1Q8Meta } from "/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/categories/[slug].vue?macro=true";
import { default as indexZW28ePVlyrMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/categories/index.vue?macro=true";
import { default as checking_partnersyicH2XQOpQMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/checking_partners.vue?macro=true";
import { default as contacts7b4ebvtyjpMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/contacts.vue?macro=true";
import { default as indexhy3jCpDcjKMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/delete-account/index.vue?macro=true";
import { default as indexJ6FrkzxTeuMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/index.vue?macro=true";
import { default as indexWrBPEjXnfZMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/info-for-partners/index.vue?macro=true";
import { default as _91slug_93lZl5GdQ4E2Meta } from "/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/kuhni/[slug].vue?macro=true";
import { default as indexalX8tQH4zWMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/kuhni/index.vue?macro=true";
import { default as offerJOuL84RCzuMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/offer.vue?macro=true";
import { default as indexG5TpHpZCC4Meta } from "/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/partner-apply-from-mobile/index.vue?macro=true";
import { default as privacyMwfapc2VvpMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/privacy.vue?macro=true";
import { default as addresesP3e1lMJ6kjMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/profile/addreses.vue?macro=true";
import { default as indexeJPDpLUcBWMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/profile/index.vue?macro=true";
import { default as info6viGO6R8pDMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/profile/info.vue?macro=true";
import { default as reviewsXJEYWWASfKMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/profile/reviews.vue?macro=true";
import { default as _91slug_93c1KS95NjLxMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/restaurants/[slug].vue?macro=true";
import { default as indexsf3IkuQLY1Meta } from "/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/restaurants/index.vue?macro=true";
import { default as searchlnCfyGp6gUMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/search.vue?macro=true";
import { default as standarts_partnerszwVMpwrLz5Meta } from "/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/standarts_partners.vue?macro=true";
import { default as terms_of_useGlaGe29VYbMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/terms_of_use.vue?macro=true";
import { default as indexMySwuoIGuaMeta } from "/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/wish/index.vue?macro=true";
export default [
  {
    name: "about-company",
    path: "/about-company",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/about-company/index.vue").then(m => m.default || m)
  },
  {
    name: "actions-slug",
    path: "/actions/:slug()",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/actions/[slug].vue").then(m => m.default || m)
  },
  {
    name: "actions",
    path: "/actions",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/actions/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "bluda-slug",
    path: "/bluda/:slug()",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/bluda/[slug].vue").then(m => m.default || m)
  },
  {
    name: "bluda",
    path: "/bluda",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/bluda/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-payment-status",
    path: "/cabinet/payment/status",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/cabinet/payment/status.vue").then(m => m.default || m)
  },
  {
    name: "cart",
    path: "/cart",
    meta: index7aDTlmHafdMeta || {},
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "cart-success",
    path: "/cart/success",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/cart/success.vue").then(m => m.default || m)
  },
  {
    name: "categories-slug",
    path: "/categories/:slug()",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/categories/[slug].vue").then(m => m.default || m)
  },
  {
    name: "categories",
    path: "/categories",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: "checking_partners",
    path: "/checking_partners",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/checking_partners.vue").then(m => m.default || m)
  },
  {
    name: "contacts",
    path: "/contacts",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: "delete-account",
    path: "/delete-account",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/delete-account/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "info-for-partners",
    path: "/info-for-partners",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/info-for-partners/index.vue").then(m => m.default || m)
  },
  {
    name: "kuhni-slug",
    path: "/kuhni/:slug()",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/kuhni/[slug].vue").then(m => m.default || m)
  },
  {
    name: "kuhni",
    path: "/kuhni",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/kuhni/index.vue").then(m => m.default || m)
  },
  {
    name: "offer",
    path: "/offer",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/offer.vue").then(m => m.default || m)
  },
  {
    name: "partner-apply-from-mobile",
    path: "/partner-apply-from-mobile",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/partner-apply-from-mobile/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "profile-addreses",
    path: "/profile/addreses",
    meta: addresesP3e1lMJ6kjMeta || {},
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/profile/addreses.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexeJPDpLUcBWMeta || {},
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-info",
    path: "/profile/info",
    meta: info6viGO6R8pDMeta || {},
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/profile/info.vue").then(m => m.default || m)
  },
  {
    name: "profile-reviews",
    path: "/profile/reviews",
    meta: reviewsXJEYWWASfKMeta || {},
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/profile/reviews.vue").then(m => m.default || m)
  },
  {
    name: "restaurants-slug",
    path: "/restaurants/:slug()",
    meta: _91slug_93c1KS95NjLxMeta || {},
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/restaurants/[slug].vue").then(m => m.default || m)
  },
  {
    name: "restaurants",
    path: "/restaurants",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/restaurants/index.vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "standarts_partners",
    path: "/standarts_partners",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/standarts_partners.vue").then(m => m.default || m)
  },
  {
    name: "terms_of_use",
    path: "/terms_of_use",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/terms_of_use.vue").then(m => m.default || m)
  },
  {
    name: "wish",
    path: "/wish",
    component: () => import("/var/www/sakhfood/frontend/sakhfood-front-20240918120338/pages/wish/index.vue").then(m => m.default || m)
  }
]