import type { ServerResponseCommonType } from '~/types/commons';
import type { SiteSettingsResponce } from './types/SiteSettingsTypes';
import { siteSettingsStore as store } from '~/stores/site-settings-store';

type TransportSiteSettingsType = {
    getSiteSettings: () => Promise<SiteSettingsResponce | null>
}

export function UseSiteSettings ():TransportSiteSettingsType {
  const { $api } = useNuxtApp();

  const siteSettingsStore = store();

  const getSiteSettings = async () => {
    const res = await $api<ServerResponseCommonType<SiteSettingsResponce>>('site/getSettings');
    if (res && res.message === 'Success') {
      siteSettingsStore.setSiteSettings(res.data);
      return res.data;
    }

    return null;
  };
  return { getSiteSettings };
}
