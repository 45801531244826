import { defineStore } from 'pinia';
import type { Ref } from 'vue';
import type { SiteSettingsResponce } from '~/composables/site-settings/types/SiteSettingsTypes';

type SiteSettingStore = {
	siteSettings: Ref<SiteSettingsResponce | null>;
	setSiteSettings:(settings: SiteSettingsResponce) => void;
}
export const siteSettingsStore = defineStore('site-setting-store', ():SiteSettingStore => {
  const siteSettings = ref<SiteSettingsResponce | null>(null);

  function setSiteSettings (settings: SiteSettingsResponce) {
	  siteSettings.value = settings;
  }

  return {
	  siteSettings,
	  setSiteSettings
  };
});
