import type { FetchError } from 'ofetch';
import type { ServerResponseCommonType } from '~/types/commons';
import type { CategoryType } from '~/composables/category/types/CategoryType';
import { categoryStore as store } from '~/stores/category-store';
import type { Ref } from 'vue';

type UseCategoryType = {
	getCategoryList:()=>Promise<CategoryType[]>;
	getCategoryBySlug: (slug:string) => Promise<CategoryType | null>;
	categoryList:Ref<CategoryType[]>;
}

export function useCategory ():UseCategoryType {
  const { $api } = useNuxtApp();

  const categoryStore = store();
  const { categoryList } = storeToRefs(categoryStore);

  async function getCategoryList ():Promise<CategoryType[]> {
    const res = await $api<ServerResponseCommonType<CategoryType[]>>('category/getList')
	    .catch((err:FetchError) => {
        throw err;
      });

    if (res && res.message === 'Success') {
      console.log(res.data);
	    categoryStore.setCategoryList(res.data);
      return res.data;
    }

    return [];
  }
  async function getCategoryBySlug (slug:string):Promise<CategoryType | null> {
    const res = await $api<ServerResponseCommonType<CategoryType>>('category/getBySlug', {
      query: { slug }
    }).catch((err:FetchError) => {
      throw err;
    });

    if (res && res.message === 'Success') {
      return res.data;
    }

    return null;
  }
  return {
	  categoryList,
    getCategoryBySlug,
	  getCategoryList
  };
}
