<template>
  <div :class="rootClass">
    <ul :class="headerClass">
      <li
        v-for="tab in tabs"
        :key="tab.props?.name"
        :class="[headerItemClass, tab.props?.name == activeTab ? headerItemActiveClass : headerItemNotActiveClass]"
        @click="updateActiveTab(tab.props?.name)"
      >
        {{ tab.props?.title }}
      </li>
    </ul>
    <slot />
  </div>
</template>

<script lang="ts" setup>
import type { PropsUI } from './type';

const props = defineProps<{
	ui?: Partial<PropsUI>
}>();

const UI:PropsUI = {
  root: '',
  header: 'flex bg-#f5f8f9 rounded-2.5 text-3.25 text-seryy w-fit',
  headerItem: 'hover:text-white hover:bg-firmennyy transition-colors cursor-pointer py-2.75 px-5.5 rounded-2.5',
  headerItemActive: 'text-white bg-firmennyy',
  headerItemNotActive: ''
};

const rootClass = computed(() => {
  return props.ui?.root ?? UI.root;
});
const headerClass = computed(() => {
  return props.ui?.header ?? UI.header;
});
const headerItemClass = computed(() => {
  return props.ui?.headerItem ?? UI.headerItem;
});
const headerItemActiveClass = computed(() => {
  return props.ui?.headerItemActive ?? UI.headerItemActive;
});
const headerItemNotActiveClass = computed(() => {
  return props.ui?.headerItemNotActive ?? UI.headerItemNotActive;
});

const slots = useSlots();
const defaultSlot = slots.default;

const tabs = computed(() => {
  return defaultSlot
    ? defaultSlot().filter((child) => {
      return (child.type as { __TAB__?: true }).__TAB__;
    })
    : [];
});

watch(tabs, (newValue, oldValue) => {
  if (newValue.length >= oldValue.length) { return; }
  if (newValue.some((a) => a.props?.name !== activeTab.value)) { updateActiveTab(tabs.value.find(() => true)?.props?.name); }
});

const activeTab = defineModel<string>();

function updateActiveTab (name:string) {
  activeTab.value = name;
}

provide(Symbol.for('activeTab'), activeTab);

if (tabs.value.length > 0 && activeTab.value === undefined) { updateActiveTab(tabs.value.find(() => true)?.props?.name); }
</script>
