import { defineStore } from 'pinia';
import type { RestaurantListType, RestaurantType } from '~/composables/restaurant/types/RestaurantType';
import type { Ref } from 'vue';

type RestaurantStoreType = {
	restaurantList: Ref<RestaurantListType>;
	setRestaurantList:(restaurants: RestaurantListType) => void;
}
export const restaurantStore = defineStore('restaurant-store', ():RestaurantStoreType => {
  const restaurantList = ref<RestaurantListType>({ count: 0, list: [] });

  function setRestaurantList (restaurants: RestaurantListType) {
    restaurantList.value = restaurants;
  }

  return {
    restaurantList,
	  setRestaurantList
  };
});
