<template>
  <TransitionModal>
    <LazyRestaurantProductModal
      v-if="isProductPopupOpen"
      @closeModal="closeProductPopup"
    />
  </TransitionModal>
  <TransitionModal>
    <LazyModalAddAddress
      v-if="isAddressPopupOpen"
      @closeModal="closeAddressPopup"
    />
  </TransitionModal>
  <TransitionModal>
    <LazyReviewsRestaurantModal
      v-if="isRestaurantReviewsPopupOpen"
      @closeModal="closeRestaurantReviewsPopup"
    />
  </TransitionModal>
  <TransitionModal>
    <LazyAuthenticationPartner
      v-if="isPartnerPopupOpen"
      @closeModal="closePartnerPopup"
    />
  </TransitionModal>
</template>

<script setup lang="ts">
import { useProductPopup } from '~/composables/popups/useProductPopup';
import { useAddingAddressPopup } from '~/composables/popups/useAddingAddressPopup';
import { useRestaurantReviewsPopup } from '~/composables/popups/useRestaurantReviewsPopup';
import { usePartnersPopup } from '~/composables/popups/usePartnersPopup';
const { isPartnerPopupOpen, closePartnerPopup } = usePartnersPopup();
const { isProductPopupOpen, closePopup: closeProductPopup } = useProductPopup();
const { isAddressPopupOpen, closePopup: closeAddressPopup } = useAddingAddressPopup();
const { isRestaurantReviewsPopupOpen, closePopup: closeRestaurantReviewsPopup } = useRestaurantReviewsPopup();
</script>

<style scoped lang="scss"></style>
