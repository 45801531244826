import type { Ref } from 'vue';
import type { RestaurantListType } from '@/composables/restaurant/types/RestaurantType';

export function formatPhoneNumber (phoneNumber :string | undefined) {
  if (!phoneNumber) { return ''; }
  return `+${phoneNumber[0]} (${phoneNumber.slice(1, 4)}) ${phoneNumber.slice(4, 7)} ${phoneNumber.slice(7, 9)} ${phoneNumber.slice(9)}`;
}

export const sortByPriceRange = (originalRestaurantsRef: Ref<RestaurantListType | undefined>, restaurantsRefParam: Ref<RestaurantListType | undefined>, minPrice?: number, maxPrice?: number) => {
  // Проверяем наличие данных в originalRestaurantsRef
  if (originalRestaurantsRef.value && originalRestaurantsRef.value.list) {
    // Если ценовой диапазон не задан, возвращаем исходный список
    if (minPrice === 0 && maxPrice === 0) {
      restaurantsRefParam.value = originalRestaurantsRef.value;
    }

    // Фильтруем рестораны по заданному ценовому диапазону
    const filteredList = originalRestaurantsRef.value.list.filter((restaurant) => {
      const avgCheck = restaurant.additional_param?.avg_check;
      // Проверяем наличие и соответствие среднего чека заданному диапазону
      if (avgCheck === undefined || avgCheck === 0) { return false; }
      if (minPrice !== undefined && minPrice !== 0 && avgCheck < minPrice) { return false; }
      if (maxPrice !== undefined && maxPrice !== 0 && avgCheck > maxPrice) { return false; }
      return true;
    });

    // Обновляем restaurantsRefParam отфильтрованным списком
    restaurantsRefParam.value = { ...originalRestaurantsRef.value, list: filteredList };
  }
};

export const sortByRating = (sortByRef: Ref<string>, restaurantsRefParam: Ref<RestaurantListType | undefined>) => {
  sortByRef.value = 'rating';
  if (restaurantsRefParam.value && restaurantsRefParam.value.list) {
    const sortedList = [...restaurantsRefParam.value.list].sort((a, b) => b.average_score - a.average_score);
    restaurantsRefParam.value = { ...restaurantsRefParam.value, list: sortedList };
  }
};

export const sortByName = (sortByRef: Ref<string>, restaurantsRefParam: Ref<RestaurantListType | undefined>) => {
  sortByRef.value = 'title';
  if (restaurantsRefParam.value && restaurantsRefParam.value.list) {
    const sortedList = [...restaurantsRefParam.value.list].sort((a, b) => a.title.localeCompare(b.title));
    restaurantsRefParam.value = { ...restaurantsRefParam.value, list: sortedList };
  }
};
