export default defineNuxtPlugin({
  name: 'customFetch',

  setup (_nuxtapp) {
    const { baseApiUrl } = useRuntimeConfig().public;

	  const sessionToken = useCookie('sessionToken', { maxAge: (60 * 60 * 24 * 30 * 12), path: '/' });

	  if (!sessionToken.value) {
		  sessionToken.value = `f-${[...Array(11)].map(() => (~~(Math.random() * 36)).toString(36)).join('')}`;
	  }

    const cookiesHeaders = useRequestHeaders(['cookie']);

    const headers: { [key: string]: string } = {
      Accept: 'application/json',
      ...cookiesHeaders,
	    'X-Session-Token': sessionToken.value
    };

    const ofetchInstanse = $fetch.create({
	    baseURL: baseApiUrl,
      // baseURL: '/api-proxi/',
      headers: {
        ...headers
      },

      onRequest ({ request, options, error }) {
        const authToken = useCookie('authToken');

        if (authToken.value) {
	        options.headers = options.headers || {};
          (options.headers as {[key:string]:string}).Authorization = `Bearer ${authToken.value}`;
        }
      }
    });

    return {
      provide: {
        api: ofetchInstanse
      }
    };
  }
});
