import { defineStore } from 'pinia';
import type { Ref } from 'vue';
import type { ProductType } from '~/composables/product/types/UseProductType';

type RestaurantStoreType = {
	productList: Ref<ProductType[]>;
	setProductList:(restaurants: ProductType[]) => void;
}
export const productStore = defineStore('product-store', ():RestaurantStoreType => {
  const productList = ref<ProductType[]>([]);

  function setProductList (restaurants: ProductType[]) {
	  productList.value = restaurants;
  }

  return {
	  productList,
	  setProductList
  };
});
