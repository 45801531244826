export const useAuthPopup = () => {
  const isModalShow = useState<boolean>('auth-modal', () => false);

  const openModal = () => {
    isModalShow.value = true;
  };

  const closeModal = () => {
    isModalShow.value = false;
  };

  return { isModalShow, closeModal, openModal };
};
