<template>
  <div
    class="relative"
    @mouseenter="isShow = true"
    @mouseleave="isShow = false"
  >
    <div class="dropdown-header group/dropdownh">
      <button
        type="button"
        class="flex gap-1.5 items-center text-tekstovyy text-5 font-500 leading-0.8 -tracking-0.01em text-left justify-between w-full"
      >
        <slot
          name="dropdown-header-content"
          :is-show="isShow"
        >
          <NuxtLink :to="`${urlForTitle}`">
            {{ title }}
          </NuxtLink>
        </slot>
        <slot
          name="dropdown-header-icon"
          :is-dropdown-item-show="isShow"
        >
          <span class="text-3 transition-transform duration-400 text-firmennyy i-custom:dropdown-select"></span>
        </slot>
      </button>
    </div>
    <Transition
      enter-active-class="transition-opacity"
      leave-active-class="transition-opacity"
      enter-from-class="opacity-0"
      leave-to-class="opacity-0"
    >
      <slot
        name="dropdown-wrapper"
        :is-show="isShow"
      >
        <div
          v-show="isShow"
          class=""
        >
          <slot />
        </div>
      </slot>
    </Transition>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  isShow: { type: Boolean, default: false },
  title: { type: String, default: '' },
  urlForTitle: { type: String, default: '/' }
});

const isShow = ref(props.isShow);

const slots = defineSlots<{
	default: any,
	'dropdown-header-content'?: any,
	'dropdown-header-icon'?: any,
	'dropdown-wrapper'?: any,
}>();
</script>
