<template>
  <div class="relative">
    <Button
      class="max-lg:p-3 w-full"
      color="seryy"
      prefix-icon="i-custom:profile"
      :ui="{rounded:'rounded-2.5'}"
      @click="profileHandler"
    >
      <ClientOnly>
        <template #fallback />
        <span class="max-lg:hidden">{{ profileBtnLabel }}</span>
      </ClientOnly>
    </Button>
    <ClientOnly>
      <LazyProfileDropdown
        v-if="isLoggedIn && profileDropdownShow"
        @closeDropdown="profileDropdownClose"
      />
    </ClientOnly>
    <TransitionModal>
      <LazyAuthenticationModal v-if="isModalShow" />
    </TransitionModal>
  </div>
</template>

<script setup lang="ts">

import { useAuthPopup } from '~/composables/popups/useAuthPopup';
import { useUserAuth } from '~/composables/user/useUserAuth';

const { isLoggedIn, currentUser } = useUserAuth();
const { openModal, isModalShow } = useAuthPopup();

function profileHandler () {
  if (!isLoggedIn.value) {
	  openModal();
    return;
  }
	  profileDropdownOpen();
}

const profileBtnLabel = computed(() => {
  if (isLoggedIn.value) {
		 return currentUser.value?.name || currentUser.value?.login || '';
  }
  return 'Войти';
});

const profileDropdownShow = ref(false);

const profileDropdownOpen = () => {
  profileDropdownShow.value = true;
};

const profileDropdownClose = () => {
  profileDropdownShow.value = false;
};
</script>
