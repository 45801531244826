<template>
  <Dropdown :url-for-title="urlForTitle.slug">
    <template #dropdown-wrapper="{ isShow }">
      <div
        v-show="isShow"
        class="absolute z-10 left-0 top-full pt-5"
      >
        <div class="overflow-hidden bg-white rounded-4 shadow-[0_6px_18px_0] shadow-black/16 pt-2.5 pb-4 pl-1.5 pr-3 w-screen max-w-146">
          <div class="grid grid-cols-2 max-h-78 overflow-auto custom-scroll pr-1">
            <template
              v-for="(item, idx) in items"
              :key="item.title"
            >
              <NuxtLink
                v-if="idx <= 5"
                :to="`${urlForTitle.slug}/${item.slug}`"
                class="flex items-center gap-2.5 text-tekstovyy border-y-6 border-x-8 border-transparent hover:text-firmennyy hover:bg-#f5f8f9 hover:border-#f5f8f9 transition-colors text-4.5 leading-0.8 -tracking-0.01em rounded-2.5"
              >
                <div class="overflow-hidden size-10 rounded-1.5 ring-0.5 p-0.5 ring-border/50 ring-inset bg-white">
                  <NuxtImg
                    width="80"
                    height="80"
                    class="w-full h-full object-center object-contain"
                    :src="`${baseUrl}${item.img_path}`"
                    alt="icon"
                    loading="lazy"
                    placeholder
                    fit="contain"
                  />
                </div>
                {{ item.title }}
              </NuxtLink>
            </template>
          </div>
          <Button
            :tag="NuxtLink"
            :to="`${urlForTitle.slug}`"
            color="green-light"
            class="cursor-pointer mt-1.5 w-fit ml-2"
            :ui="{ padding: 'px-7 py-3', text: 'text-3.5', rounded: 'rounded-3' }"
          >
            {{ urlForTitle.label }} {{ items.length }}
          </Button>
        </div>
      </div>
    </template>
  </Dropdown>
</template>

<script setup lang="ts">
import { NuxtLink } from '#components';

const { baseUrl } = useRuntimeConfig().public;
defineProps<{
	urlForTitle: {
		slug: string,
		label: string,
	},
	items: {
		title: string,
		slug: string,
		img_path: string
	}[]
}>();
</script>
