import type {
  AddressFiasId, ChangeProfileInfoBody,
  UserAddressReqType,
  UserAddressType,
  UserUpdateAddressRequestType
} from '~/composables/user/types/UserTypes';
import type { AddressFormInitType, ServerResponseCommonType } from '~/types/commons';
import { storeToRefs, useNuxtApp } from '#imports';
import type { ComputedRef } from 'vue';
import { userStore as store } from '~/stores/user-store';

type UserInfoType = {
	userAddAddress: (options: UserAddressReqType) => Promise<boolean>;
	userUpdateAddress: (options: UserUpdateAddressRequestType) => Promise<boolean>;
	userRemoveAddress: (id: number) => Promise<boolean>;
	userCurrentAddress: ComputedRef<UserAddressType | UserAddressReqType | null>;
	userAddCurrentAddress:(address:UserAddressReqType | null)=> void;
	changeProfileInfo:(options:ChangeProfileInfoBody)=>Promise<boolean>;
}

export function useUserInfo ():UserInfoType {
  const { $api } = useNuxtApp();
  const userStore = store();
  const { currentAddress } = storeToRefs(userStore);

  const userCurrentAddress = computed(() => currentAddress.value);

  async function userAddAddress (options: UserAddressReqType): Promise<boolean> {
    const res = await $api<ServerResponseCommonType>('user/address/add', {
      method: 'POST',
      body: { ...options }
    }).catch((err) => { throw err; });

    return res.message === 'Success';
  }

  async function userUpdateAddress (options: UserUpdateAddressRequestType): Promise<boolean> {
    const res = await $api<ServerResponseCommonType>('user/address/update', {
      method: 'POST',
      body: { ...options }
    }).catch((err) => { throw err; });

    return res.message === 'Success';
  }

  async function userRemoveAddress (id: number): Promise<boolean> {
    const res = await $api<ServerResponseCommonType>('user/address/delete', {
      method: 'POST',
      body: { id }
    }).catch((err) => { throw err; });

    return res.message === 'Success';
  }
  async function changeProfileInfo (options:ChangeProfileInfoBody) {
    const res = await $api<ServerResponseCommonType>('user/changeProfile', {
      method: 'POST',
      body: { ...options }
    }).catch((err) => { throw err; });

    return res.message === 'Success';
  }
  return {
	  userCurrentAddress,
	  userAddCurrentAddress: userStore.setCurrentAddress,
	  changeProfileInfo,
    userAddAddress,
    userUpdateAddress,
    userRemoveAddress
  };
}
