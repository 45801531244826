import type { Ref } from 'vue';
import { defineStore } from 'pinia';

import type { ProductType } from '~/composables/product/types/UseProductType';
import type { RestaurantType } from '~/composables/restaurant/types/RestaurantType';
import type { WishProductWithIdentifierType, WishRestWithIdentifierType } from '~/types/commons';

export type WishListType ={
	products:ProductType[];
	restaurants:RestaurantType[];
}

type WishStoreType = {
	wishStoreList: Ref<WishListType>;
	changeWishList:(storeItem: WishRestWithIdentifierType | WishProductWithIdentifierType | null)=>void;
	wishTotalCount:Ref<number>;
}

export const wishStore = defineStore('wish-store', (): WishStoreType => {
  const wishStoreList = ref<WishListType>({
	  products: [],
	  restaurants: []
  });

  const changeWishList = (storeItem: WishRestWithIdentifierType | WishProductWithIdentifierType | null) => {
	  if (storeItem?.type === 'product') {
			  wishStoreList.value.products = storeItem.items;
		  }
	  if (storeItem?.type === 'restaurant') {
		  wishStoreList.value.restaurants = storeItem.items;
	  }
	  if (storeItem === null) {
      wishStoreList.value = {
        products: [],
        restaurants: []
      };
	  }
  };
  const wishTotalCount = computed(() => {
    return wishStoreList.value.restaurants.length + wishStoreList.value.products.length;
  });

  return {
	  wishTotalCount,
    wishStoreList,
	  changeWishList
  };
});
