<template>
  <div>
    <div class="header-wrapper">
      <Header />
    </div>
    <div class="container error-block">
      <h1 class="text-firmennyy">{{ error?.statusCode }}</h1>
      <div
        v-if="error?.statusCode === 404"
        class="text-firmennyy"
      >
        страница не найдена
      </div>
      <div
        v-if="error?.statusCode === 500"
        class="text-firmennyy"
      >
        что-то пошло не так
      </div>

      <div>
        <Button
          class="btn primary"
          @click="handleError"
        >
          На главную
        </Button>
      </div>
    </div>

    <div class="footer-wrapper">
      <Footer />
    </div>
  </div>
</template>

<script setup lang="ts">

const { error } = defineProps({
  error: Object
});

const handleError = () => {
  clearError({ redirect: '/' });
};

</script>

<style scoped lang="scss">
  .error-block {
	  min-height: calc(50vh);
	  display: flex;
	  flex-direction: column;
	  justify-content: center;
    align-items: center;
    gap: 10px;
	  margin: 0 auto;

    & > h1 {
      font-weight: 700;
      font-size: 76px;
      text-align: center;
    }

    & > div {
      font-weight: 700;
      font-size: 50px;
      text-align: center;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 1167px) {
    .error-block {
      & > h1 {
        font-size: 50px;
      }

      & > div {
        font-size: 40px;
      }
    }

  }

  @media (max-width: 640px) {
    .error-block {
      & > h1 {
        font-size: 48px;
      }

      & > div {
        font-size: 30px;
      }
    }

  }
</style>
