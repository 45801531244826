<template>
  <Transition
    enter-from-class="opacity-0"
    leave-to-class="opacity-0"
    enter-active-class="transition-opacity ease-in-out"
    leave-active-class="transition-opacity ease-in"
  >
    <slot />
  </Transition>
</template>
