// plugins/gtag.client.js
export default defineNuxtPlugin(() => {
  useHead({
    script: [
      {
        src: 'https://www.googletagmanager.com/gtag/js?id=G-PCGBEPP5R7',
        async: true
      }
    ],
    __dangerouslyDisableSanitizersByTagID: {
      'G-PCGBEPP5R7': ['innerHTML']
    }
  });

  window.dataLayer = window.dataLayer || [];
  function gtag () {
    window.dataLayer.push(arguments);
  }
  gtag('js', new Date());
  gtag('config', 'G-PCGBEPP5R7');
  console.log('g-tag initialized');
});
