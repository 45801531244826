import { useState } from '#imports';
import type { Ref } from 'vue';

type UsePartnersType = {
	isPartnerPopupOpen:Ref<boolean>;
	closePartnerPopup:()=> void;
	openPartnerPopup:()=> void;
}

export function usePartnersPopup (): UsePartnersType {
  const isPartnerPopupOpen = useState<boolean>('is-partner-open', () => false);

  function closePartnerPopup () {
    isPartnerPopupOpen.value = false;
  }
  function openPartnerPopup () {
    isPartnerPopupOpen.value = true;
  }
  return {
	  isPartnerPopupOpen,
	  closePartnerPopup,
	  openPartnerPopup
  };
}
