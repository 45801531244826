import type { ProductType } from '~/composables/product/types/UseProductType';
import type { Ref } from 'vue';

type AddressPopupOptionsType = {}

type UseAddingAddressType = {
	addressState:Ref<AddressPopupOptionsType | null>;
	isAddressPopupOpen:Ref<boolean>;
	openPopup:(options?:AddressPopupOptionsType)=> void;
	closePopup:()=> void;
}

export function useAddingAddressPopup ():UseAddingAddressType {
  const addressState = useState<AddressPopupOptionsType | null>('adding-address-popup', () => ({}));
  const isAddressPopupOpen = useState<boolean>('is-address-open', () => false);

  function openPopup (options?:AddressPopupOptionsType) {
    isAddressPopupOpen.value = true;
    addressState.value = { ...options };
  }

  function closePopup () {
    isAddressPopupOpen.value = false;
    addressState.value = null;
  }

  return {
	  addressState,
	  isAddressPopupOpen,

	  openPopup,
	  closePopup
  };
}
