import type { ProductType } from '~/composables/product/types/UseProductType';
import type { Ref } from 'vue';

type ProductPopupOptionsType = {
	currentProduct:ProductType | null;
}

type UseProductPopupType = {
	currentFullProductState:Ref<ProductPopupOptionsType>;
	isProductPopupOpen:Ref<boolean>;
	openPopup:(options:ProductPopupOptionsType)=> void;
	closePopup:()=> void;
}

export function useProductPopup ():UseProductPopupType {
  const currentFullProductState = useState<ProductPopupOptionsType>('product-full-popup', () => ({
	  currentProduct: null
  }));

  const isProductPopupOpen = useState<boolean>('is-product-open', () => false);
  // const scrollPositionCookie = useCookie('scrollPosition');

  // function getScrollbarWidth () {
  //   // Создаем временный элемент для измерения ширины полосы прокрутки
  //   const scrollDiv = document.createElement('div');
  //   scrollDiv.style.visibility = 'hidden';
  //   scrollDiv.style.overflow = 'scroll';
  //   scrollDiv.style.position = 'absolute';
  //   scrollDiv.style.top = '-9999px';
  //   document.body.appendChild(scrollDiv);

  //   const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  //   document.body.removeChild(scrollDiv);
  //   return scrollbarWidth;
  // }

  function openPopup (options: ProductPopupOptionsType) {
    // const scrollPosition = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
    // scrollPositionCookie.value = `${scrollPosition}`; // Сохраняем значение как строку
    // console.log('Open popup, saved scroll position:', scrollPosition);
    isProductPopupOpen.value = true;
    currentFullProductState.value = { ...options };
    // const scrollbarWidth = getScrollbarWidth();

    // document.body.style.overflow = 'hidden'; // Отключение прокрутки при открытой модалке
    // document.body.style.position = 'fixed';
    // document.body.style.top = `-${scrollPositionCookie.value}px`;
    // document.body.style.width = `calc(100% - ${scrollbarWidth}px)`; // Добавление ширины полосы прокрутки
  }

  function closePopup () {
    // const scrollPosition = scrollPositionCookie.value;
    // console.log('Close popup, restoring scroll position:', scrollPosition);
    isProductPopupOpen.value = false;
    currentFullProductState.value.currentProduct = null;
    // document.body.style.overflow = ''; // Включение прокрутки при закрытой модалке
    // document.body.style.position = '';
    // document.body.style.top = '';
    // document.body.style.width = ''; // Добавление ширины полосы прокрутки

    // if (scrollPosition) {
    //   window.scrollTo(0, parseInt(scrollPosition));
    //   console.log('Scroll position restored to:', scrollPosition);
    // }
  }

  return {
	  currentFullProductState,
	  isProductPopupOpen,

	  openPopup,
	  closePopup
  };
}
