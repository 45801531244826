import type { Ref } from 'vue';
import type { UserAddressReqType, UserAddressType, UserMeType } from '~/composables/user/types/UserTypes';
import { defineStore } from 'pinia';
import type { AddressFormInitType } from '~/types/commons';

type UserStoreType = {
  userMe: Ref<UserMeType | null>;
  isUserLoggedIn: Ref<boolean>;
  setUser: (user: UserMeType | null) => void;
	currentAddress:Ref<UserAddressType | UserAddressReqType | null>;
	setCurrentAddress:(address: UserAddressReqType | null)=> void;
}

export const userStore = defineStore('user-store', (): UserStoreType => {
  const userMe = ref<UserMeType | null>(null);
  const isUserLoggedIn = ref(false);
  const currentAddress = ref<UserAddressType | UserAddressReqType | null>(null);

  function setCurrentAddress (address: UserAddressReqType | UserAddressType | null) {
    if (address?.address) {
	    currentAddress.value = { ...address, address: address.address };
      return;
    }
    if (address === null) {
      currentAddress.value = null;
    }

	  currentAddress.value = address as UserAddressType;
  }

  function setUser (user: UserMeType | null) {
    if (user) {
      userMe.value = user;
      isUserLoggedIn.value = true;
    } else {
      userMe.value = null;
      isUserLoggedIn.value = false;
    }
  };

  return {
	  currentAddress,
    userMe,
    isUserLoggedIn,
	  setCurrentAddress,
    setUser
  };
});
