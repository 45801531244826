import type { FetchError } from 'ofetch';
import type { ServerResponseCommonType } from '~/types/commons';
import type {
  AddManyProductsToCartType,
  AddToCartReqType,
  CartItemType
} from '~/composables/cart/types/CartType';
import { cartStore as store } from '~/stores/cart-store';
import type { Ref } from 'vue';

type UseCartType = {
	getCartList:()=>Promise<CartItemType[]>;
	changeCart:(options:AddToCartReqType)=>Promise<CartItemType[]>;
	addManyProductsToCart:(options:AddManyProductsToCartType) => Promise<CartItemType []>;
	clearCart: (body?: {restaurant_id: number }) => Promise<boolean>;
	cartList:Ref<CartItemType[]>;
	cartTotalCount:Ref<number>;
	cartTotalPrice:Ref<number>;
	duplicateOrdersCart:(orderId:number)=>Promise<CartItemType[]>;
}

export function useCart ():UseCartType {
  const { $api } = useNuxtApp();

  const cartStore = store();
  const { cartList, cartTotalPrice, cartTotalCount } = storeToRefs(cartStore);

  async function getCartList ():Promise<CartItemType[]> {
    const res = await $api< ServerResponseCommonType<CartItemType[]>>('cart/getActualCarts').catch((err:FetchError) => {
      throw err;
    });

    if (res && res.message === 'Success') {
	    cartStore.setCartStore(res.data);
      return res.data;
    }

    return [];
  }

  async function changeCart (options:AddToCartReqType):Promise<CartItemType[]> {
    const res = await $api<ServerResponseCommonType<CartItemType[]>>('cart/changeCartProduct', {
      method: 'POST',
      body: options
    }).catch((err) => {
      throw err;
    });

    if (res && res.message === 'Success') {
	    cartStore.setCartStore(res.data);
      return res.data;
    }

    return [];
  }

  async function addManyProductsToCart (options:AddManyProductsToCartType):Promise<CartItemType []> {
    const res = await $api<ServerResponseCommonType<CartItemType[]>>('cart/addManyProducts', {
      method: 'POST',
	    body: { ...options }
    }).catch((err:FetchError) => {
	    throw err;
    });

    if (res && res.message === 'Success') {
	    cartStore.setCartStore(res.data);
      return res.data;
    }

    return [];
  }

  async function clearCart (body: {restaurant_id: number} | undefined):Promise<boolean> {
    const res = await $api<ServerResponseCommonType>('cart/deleteCarts', {
      method: 'POST',
      body: { ...body }
    }).catch((err:FetchError) => {
      throw err;
    });

    if (res && res.message === 'Success' && !body) {
      cartStore.setCartStore(null);
      return true;
    } else if (res && res.message === 'Success') {
      getCartList();
    }

    return false;
  }

  async function duplicateOrdersCart (orderId:number):Promise<CartItemType[]> {
    const res = await $api<ServerResponseCommonType<CartItemType[]>>('cart/duplicateOrdersCart', {
      method: 'POST',
      body: { order_id: orderId }
    }).catch((err) => { throw err; });

    if (res && res.message === 'Success') {
      cartStore.setCartStore(res.data);
      return res.data;
    }

    return [];
  }

  return {
	  cartList,
	  cartTotalPrice,
	  cartTotalCount,

	  duplicateOrdersCart,
	  clearCart,
	  addManyProductsToCart,
	  changeCart,
    getCartList
  };
}
