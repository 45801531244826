<template>
  <div
    v-show="activeTab == name"
    :key="name"
    class="tab"
  >
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
defineProps({
  title: String,
  name: String
});

defineOptions({
  __TAB__: true // add this to easily find tab components from tabs
});

const activeTab = inject(Symbol.for('activeTab'), '');
</script>
