import type { ServerResponseCommonType } from '~/types/commons';
import type { SearchResponceType, searchQueryType } from './types/SearchTypes';

type TransportSearchType = {
    performSearch: (query: searchQueryType) => Promise<SearchResponceType | null>
    isLoading: Ref<boolean>
}

export function useSearch ():TransportSearchType {
  const { $api } = useNuxtApp();
  const isLoading = ref(false);

  const performSearch = async (query: searchQueryType):Promise<SearchResponceType | null> => {
    isLoading.value = true;
    const res = await $api<ServerResponseCommonType<SearchResponceType | null>>(`/search/search?query=${query.query}`
    );

    if (res && res.data) {
      isLoading.value = false;
      return res.data;
    }
    isLoading.value = false;
    return null;
  };

  return {
    performSearch,
    isLoading
  };
}
