import type { FetchError } from 'ofetch';
import type { ServerResponseCommonType } from '~/types/commons';
import type { UseProductReqType, ProductType } from '~/composables/product/types/UseProductType';
import { productStore as store } from '~/stores/product-store';
import type { Ref } from 'vue';
type UseProductType = {
	getProductList:(options?:UseProductReqType)=>Promise<ProductType[]>;
	getProductBySlug: (slug:string) => Promise<ProductType | null>;
	productList:Ref<ProductType[]>;
}

export function useProduct ():UseProductType {
  const { $api } = useNuxtApp();
  const productStore = store();
  const { productList } = storeToRefs(productStore);

  async function getProductList (options?:UseProductReqType):Promise<ProductType[]> {
    const res = await $api<ServerResponseCommonType<ProductType[]>>('product/getList', {
      query: { ...options }
    }).catch((err:FetchError) => {
      throw err;
    });

    if (res && res.message === 'Success') {
      if (!options) {
        productStore.setProductList(res.data);
      }

      return res.data;
    }

    return [];
  }

  async function getProductBySlug (slug:string):Promise<ProductType | null> {
    const res = await $api<ServerResponseCommonType<ProductType>>('product/getBySlug', {
      query: { slug }
    }).catch((err:FetchError) => {
      throw err;
    });

    if (res && res.message === 'Success') {
      return res.data;
    }

    return null;
  }

  return {
	  productList,
    getProductBySlug,
	  getProductList
  };
}
