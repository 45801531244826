<template>
  <section class="flex min-h-screen flex-col justify-start">
    <Header v-bind="(headerAttrs as object)" />
    <NuxtLoadingIndicator color="#FFC115" />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>

    <Footer class="mt-10 lg:mt-22.5" />
    <ModalAppWrapper />
  </section>
</template>
<script lang="ts" setup>
import { seoHandlers } from '~/utils';
import ModalAppWrapper from '~/components/Modal/ModalAppWrapper.vue';
import { useHead } from '#imports';
import { UseSiteSettings } from './composables/site-settings/useSiteSettings';

const { setSEOTitles } = seoHandlers();
const { getSiteSettings } = UseSiteSettings();

setSEOTitles({
  seoTitle: 'Доставка еды  в Южно-Сахалинске',
  seoDescription: 'Доставка еды  в Южно-Сахалинске'
});

const { data } = await useAsyncData('siteSettings', () => getSiteSettings(), {
  immediate: true
});

useHead({
  meta: [
    // { name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no' }
    { name: 'yandex-verification', content: '367149c7af728f2f' }

  ],
  link: [
    { rel: 'icon', type: 'image/x-icon', href: '/fav/favicon.ico' },
    { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/fav/favicon-16x16.png' },
    { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/fav/favicon-32x32.png' },
    { rel: 'apple-touch-icon', type: 'image/png', sizes: '180x180', href: '/fav/apple-touch-icon.png' },
    { rel: 'icon', type: 'image/png', sizes: '192x192', href: '/fav/android-chrome-192x192' },
    { rel: 'icon', type: 'image/png', sizes: '512x512', href: '/fav/android-chrome-512x512' }
  ]
});

/* добавить типы для кастоных мета данных страниц */
const route = useRoute();
const headerAttrs = computed(() => route.meta?.headerAttrs ?? {});
</script>
