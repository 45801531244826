import type { Ref } from 'vue';

type RestaurantReviewsPopupOptionsType = {
	restaurantId:number | undefined;
	canCreateFeedback:boolean;
}
type UseRestaurantReviewsPopupType = {
	currentFullRestaurantReviewsState: Ref<RestaurantReviewsPopupOptionsType>;
	isRestaurantReviewsPopupOpen:Ref<boolean>;
	openPopup:(options:RestaurantReviewsPopupOptionsType)=> void;
	closePopup:()=> void;
}

export function useRestaurantReviewsPopup ():UseRestaurantReviewsPopupType {
  const currentFullRestaurantReviewsState = useState<RestaurantReviewsPopupOptionsType>('restaurant-reviews-full-popup', () => ({
	  restaurantId: undefined,
	  canCreateFeedback: false
  }));

  const isRestaurantReviewsPopupOpen = useState<boolean>('restaurant-reviews-open', () => false);

  function openPopup (options:RestaurantReviewsPopupOptionsType) {
	  currentFullRestaurantReviewsState.value = { ...options };
    isRestaurantReviewsPopupOpen.value = true;
  }

  function closePopup () {
    isRestaurantReviewsPopupOpen.value = false;
	  currentFullRestaurantReviewsState.value.restaurantId = undefined;
  }

  return {
	  currentFullRestaurantReviewsState,
	  isRestaurantReviewsPopupOpen,
	  openPopup,
	  closePopup
  };
}
