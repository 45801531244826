import validate from "/var/www/sakhfood/frontend/sakhfood-front-20240918120338/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45trailing_45slash_45global from "/var/www/sakhfood/frontend/sakhfood-front-20240918120338/middleware/redirect-trailing-slash.global.ts";
import manifest_45route_45rule from "/var/www/sakhfood/frontend/sakhfood-front-20240918120338/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/var/www/sakhfood/frontend/sakhfood-front-20240918120338/middleware/auth.ts")
}